import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, {
    id: "show_bg",
    color: "tertiary",
    "router-link": '/activity/'+$props.activity.id,
    class: "card_margin"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($props.activity.name), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_card_subtitle, { color: "dark" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($props.activity.group), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_subtitle, { color: "dark" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: $setup.calendarOutline }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($props.activity.date), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_subtitle, { color: "dark" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: $setup.timeOutline }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($options.time), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_chip, { color: "light" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: $setup.people }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, { style: {"font-size":"18px"} }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($props.activity.participants), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["router-link"]))
}