
import {IonCard, IonCardHeader, IonCardSubtitle, IonLabel} from '@ionic/vue';

export default {
  name: 'JoinCard',
  props: {},
  components: {
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonLabel
  },
  computed: {},
  setup() {
    return {}
  }
}

