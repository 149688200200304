
import {IonList, IonSegment, IonSegmentButton, IonListHeader, IonButton, IonLabel, IonModal} from '@ionic/vue';
import {chevronUpOutline, add} from 'ionicons/icons';
import BaseLayout from "@/components/BaseLayout.vue";
import ActivityCard from "@/components/card/ActivityCard.vue";
import EventCard from "@/components/card/EventCard.vue";
import MemberItem from "@/components/item/MemberItem.vue";
import groupCard from "@/components/card/GroupCard.vue";
import JoinCard from "@/components/card/JoinCard.vue";
import JoinResponseModal from "@/components/modal/JoinResponseModal.vue";

export default {
  name: 'Group',
  components: {
    BaseLayout,
    IonList,
    EventCard,
    ActivityCard,
    IonListHeader,
    MemberItem,
    IonSegment,
    IonSegmentButton,
    groupCard,
    IonButton,
    IonLabel,
    JoinCard,
    IonModal,
    JoinResponseModal
  },
  setup() {
    return {
      chevronUpOutline,
      add
    }
  },
  props: ['id'],
  created() {
    this.loadGroup(this.id);
  },
  computed: {
    group() {
      return this.$store.getters['group/group'];
    },
    signup() {
      return {
        going: this.group.member,
        action: 'group',
        disableButton: this.pending
      };
    },
    groupAdmin() {
      return this.group.member
          && this.group.groupRole != null
          && this.group.groupRole === 'ADMIN';
    },
    viewGroup(){
      return  !this.group.privateGroup || (this.group.privateGroup && this.group.member);
    },

    pending(){
      return this.group.groupRole != null && this.group.groupRole === 'PENDING';
    },
    settings(){
      return{ type:'group', canEdit: this.groupAdmin}
    }

  },
  methods: {
    segmentChanged(ev: CustomEvent) {
      this.active = ev.detail.value;
    },
    loadGroup(teamId) {
      if (teamId)
        return this.$store.dispatch('group/group', teamId);
    },
    openRespond(id){
      this.user = id;
      this.responseOpen = !this.responseOpen;
    },
    closeRespond(){
      this.responseOpen = false;
    }
  },
  watch: {
    groupId(newGroupId) {
      this.loadGroup(newGroupId);
    },
  },
  data() {
    return {
      active: 'activities',
      fab: false,
      responseOpen: false,
      user: 0
    }
  }
}

