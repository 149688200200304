
    import {  IonGrid, IonRow, IonCol, IonCard, IonCardTitle, IonCardSubtitle, IonIcon, IonChip, IonLabel  } from '@ionic/vue';
    import { people,  calendarOutline} from 'ionicons/icons';

    export default  {
    name: 'EventCard',
        props: {
            'event': Object
        },
        components: {
            IonGrid,
            IonRow,
            IonCol,
            IonCard,
            IonCardTitle,
            IonCardSubtitle,
          IonIcon,
          IonChip,
          IonLabel

        },
        setup() {
            return {
              people,
              calendarOutline,
            }
        }
    }

