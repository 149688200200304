
import {
  IonGrid, IonRow, IonCol, IonCard, IonCardTitle,
  IonCardSubtitle, IonIcon, IonLabel, IonChip
} from '@ionic/vue';
import {people, peopleOutline, calendarOutline, timeOutline} from 'ionicons/icons';
import dayjs from "dayjs";
import ImageHelper from "@/helpers/ImageHelper";

const __default__ = {
  name: 'ActivityCard',
  props: {
    'activity': Object
  },
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardTitle,
    IonCardSubtitle,
    IonIcon,
    IonLabel,
    IonChip
  },
  setup() {
    return {
      people,
      peopleOutline,
      calendarOutline,
      timeOutline
    }
  },
  computed: {
    bgImg() {
      return ImageHelper.backgroundImage('activity', this.activity.imageUrl);
    },
    time() {
      if (this.activity !== undefined) {
        return dayjs(this.activity.startTime).format('hh:mm A');
      }
      return '';
    },
  }
}

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "4ae04e16": (_ctx.bgImg)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__