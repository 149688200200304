

import { IonContent, IonHeader, IonToolbar, IonList, IonItem, IonLabel, IonTitle,} from '@ionic/vue';

export default {
  name:'JoinResponseModal',
  emits:['settingsClose'],
  props:['user'],
  components:{
    IonContent,
    IonHeader,
    IonToolbar,
    IonItem,
    IonList,
    IonLabel,
    IonTitle
  },
  setup(){
    return {
    }
  },
  methods: {
    cancel() {
      this.$emit("settingsClose");
    },

    handleResponse(role, joinFlag) {
      const join = {
        join: joinFlag,
        role: role,
        participationId: this.$route.params.id,
        athleteId: this.user
      };
      this.$store.dispatch('group/joinRequest', join);
    },

  },
  computed:{
  },
  data() {
    return {
      success: false,
      error: false,
    }
  },
}
