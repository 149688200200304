
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonChip,
  IonLabel,
  IonCardContent,
  alertController,
  IonButton

} from '@ionic/vue';
import {lockClosedOutline} from 'ionicons/icons';

export default {
  name: 'GroupCard',
  props: {
    simple: {
      type: Boolean,
      default: false
    },
    group: Object,
    pendingJoin: {
      type: Boolean,
      default: false
    }
  },
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonChip,
    IonLabel,
    IonCardContent,
    IonButton
  },
  setup() {
    return {
      lockClosedOutline
    }
  },
  methods: {
    getImage(name) {
      return process.env.VUE_APP_IMAGE_URL + 'groups/' + name;
    },
    async presentAlertConfirm() {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Confirm',
            message: 'Join Group',
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                id: 'cancel-button',
                handler: blah => {
                  console.log('Confirm Cancel:', blah)
                },
              },
              {
                text: 'Yes',
                id: 'confirm-button',
                handler: () => {
                  const join = {
                    join: true,
                    participationId: this.$route.params.id,
                    athleteId: this.$store.getters.accountId
                  }
                  this.$store.dispatch('group/joinGroup', join);
                },
              },
            ],
          });
      return alert.present();
    },
  },
  computed: {
    groupLink() {
      return '/groups/' + this.group.id;
    },
    joinButton() {
      return !this.group.member && this.group.privateGroup ? 'Request to join' : 'join';
    },

  }
}
